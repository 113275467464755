import React, { Ref } from "react";
import PropTypes from "prop-types";
import './YoutubeEmbed.css'

interface iyoutube {
    ref: any
    handleOutsideClick: (event: Event) => void
    openVideo: boolean
}

export const YoutubeEmbed = ({ ref, handleOutsideClick, openVideo }: iyoutube) => {

    if(!openVideo) {
        return null
    }

    return (
        <div ref={ref} onClick={(e) => handleOutsideClick(e as any)} className="videoIframe">
            <iframe
                width="560" height="315"
                src="https://www.youtube.com/embed/H1_Dp2Tg_ts"
                title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen></iframe>
        </div>
    )
}


