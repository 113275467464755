import React, { useState, createContext, useEffect, useCallback, useContext } from 'react';
import { AppContextType, ContextProviderProps, Modal } from '../../types/Context';
import { initialStateModal } from './initialState';

const AppContext = createContext<AppContextType>(
    {
        modalWalletIsOpen: false,
        setModalWalletIsOpen: (modalWalletIsOpen: boolean) => { },

        modal: initialStateModal,
        setModal: (modal: Modal) => {},

        landMinted: 0,
        setLandMinted: (qty: number) => {},
    });

const AppContextProvider = ({ children }: ContextProviderProps) => {

    const [modalWalletIsOpen, setModalWalletIsOpen] = useState<boolean>(false)
    const [modal, setModal] = useState<Modal>(initialStateModal)
    const [landMinted, setLandMinted] = useState<number>(0)

    
    const data: AppContextType = {
        modalWalletIsOpen, setModalWalletIsOpen,
        modal, setModal,
        landMinted, setLandMinted
    }

    return (
        <AppContext.Provider value={data}>
            {children}
        </AppContext.Provider>
    )

}

const useAppContext = () => useContext(AppContext)

export {AppContextProvider, useAppContext}



