import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import abiToken from "../abi/token.json";
import abiUSDC from "../abi/usdc.json";
import abiUSDT from "../abi/usdt.json";
import abiNft from "../abi/land.json";


export const API_KEY = "L52S1NDdCrblzhCAaiCGXoheN438Cxq9cDDSLzWaCYBLkmzFH0Ve8AQFqMP87owiZaaSpTNVD4GXO2AzjMkiUkNIRIeXlVO02geYe4G12bzy5UOPjAKoqLZb6UHzv6Pz"
export const metamaskAppLink="https://metamask.app.link/dapp/lands.thenemesis.io/"
export const coinBaseAppLink='https://go.cb-w.com/dapp?cb_url=https%3A%2F%2Flands.thenemesis.io'
export const endpoint = "https://api.thenemesis.io/v6"
export const MAX_SUPPLY = 11520


export const CHAIN = "ethereum";
export const CHAIN_ID = 1; 

// export const CONTRACT_ADDRESS_USDC = "0x72db9Ac7d1c3C1643Acb16CBA4224e6EF3060D5C"; // test
// export const CONTRACT_ADDRESS_USDT = "0xa7f6a671960c74D847fe9Ba02A9A1a303BEA566F"; // test
// export const CONTRACT_ADDRESS_NEMS = "0x4aC15ECE6428C61d0a8055A6ED8bE541A0c74c7B"; // test
// export const CONTRACT_ADDRESS_NFT =  "0x6FE65A12f7FE0f9caF29A444E459b3C0B9FA4278"; // test

export const CONTRACT_ADDRESS_USDC = "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"; 
export const CONTRACT_ADDRESS_USDT = "0xdac17f958d2ee523a2206206994597c13d831ec7"; 
export const CONTRACT_ADDRESS_NEMS = "0xb435A47eCea7F5366b2520e45B9beD7E01d2FFAe"; 
export const CONTRACT_ADDRESS_NFT =  "0xF1B2B855817CbFFF5359e0f69a7e8a21a7c9D4ac"; 

export const ABI_TOKEN = abiToken;
export const ABI_USDT = abiUSDT;
export const ABI_USDC = abiUSDC;
export const ABI_NFT = abiNft;

export const INFURA_KEY = "64ea5b3bd2c14171a87722b5cd6b1535";

// Metamask
const injectedConnector = new InjectedConnector({
  supportedChainIds: [CHAIN_ID]
});

// Wallet connect
const walletConnectConnector = new WalletConnectConnector({
  rpc: {
    1: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  chainId: 1, 
});

//coinbase
const coinbaseConnector = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  appName: "NemesisLands",
  supportedChainIds: [1],
  darkMode: true,
});

export const connectors = {
  injected: injectedConnector,
  walletConnect: walletConnectConnector,
  coinbaseWallet: coinbaseConnector,
};