import './Modal.css'
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ModalProps {
    isOpen: boolean,
    onClose: () => void,
    childComponent: {},
    message?: boolean
}

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 998;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;  
`;

export const Modal = (prop: ModalProps) => {

    return (

        <StyledModal
            aria-labelledby="unstyled-modal-title"
            aria-describedby="unstyled-modal-description"
            open={prop.isOpen}
            onClose={prop.onClose}
            hideBackdrop={true}
        >
            <Box className={prop.message ? "w-modal-message" : "w-modal"}>
                <div className="w-modal-header ">
                    <div className="w-modal-close">
                        <IconButton onClick={prop.onClose} aria-label="close">
                            <FontAwesomeIcon color='white' icon={faClose} />
                        </IconButton>
                    </div>
                </div>
                <div className="w-modal-body">
                    <>
                        {prop.childComponent}
                    </>
                </div>
            </Box>
        </StyledModal>
    )
}