import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './Mint.css'
import clsx from 'clsx'
import { useChain } from '../../shared/hooks/useConnectChain'
import { useAppContext } from '../../shared/context/AppContext'
import Loader from '../molecules/Loader/Loader'
import axios from 'axios'
import { API_KEY, MAX_SUPPLY, endpoint } from '../../shared/const/const'
import { NumericFormat } from 'react-number-format';
import { useDebounce } from '../../shared/hooks/useDebounce'
import logo from '../../img/nems.webp'

interface iMint {
    getLands: (address: string) => void
    burnTokens: (tokensID: number[]) => void
    incrementBalance: (qty: number) => void
    limitReached: boolean
    amount: number
    availableLands: number
}

export const Mint = ({ incrementBalance, burnTokens, limitReached, amount, availableLands }: iMint) => {

    const { correctChain, mintReserved, price, mintPublic, ensName, displayFormat, contractNFT, air } = useChain();
    const { address } = useChain();

    const { setModalWalletIsOpen, setModal } = useAppContext()

    const [cost, setCost] = useState(0)
    const [totalCostWei, setTotalCostCostWei] = useState<bigint>()

    const [total, setTotal] = useState(0)
    const [termsChecked, setTermsChecked] = useState(false)
    const [publicIsOpen, setPublicIsOpen] = useState(false)
    const [tokenSelected, setTokenSelected] = useState<string[]>()
    const [tokenIdSearched, setTokenIdSearched] = useState<string>("")
    const [ownerAddress, setOwnerAddress] = useState<string>("")
    const [priceApi, setPriceApi] = useState<string>("")

    const [landSeed, setLandSeed] = useState<number>(0)
    const [loadingSeed, setLoadingSeed] = useState<boolean>(false)

    const [loadingPriceApi, setLoadingPriceApi] = useState<boolean>(false)
    const [loadingPriceChain, setLoadingPriceChain] = useState<boolean>(false)

    const debouncedSearchStartUp = useDebounce(tokenIdSearched, 500)

    const getRefQuery = () => {
        const urlParams = new URLSearchParams(window.location.search);
        let refToken_id = urlParams.get('token_id');
        if (refToken_id) {
            setTokenSelected([refToken_id])
        }
    }

    const getSeed = (tokenSelected: string) => {

        setLoadingSeed(true)
        axios.get(`${endpoint}/lands/assets/1/${tokenSelected}`, {
            headers: {
                'x_api_key': API_KEY
            }
        }).then((res) => {
            setLandSeed(res.data.seed)
            setOwnerAddress(res.data.ownerAddress)

        }).catch((err) => {
            setModal({ isOpen: true, type: "error", title: "Error loading land", message: err?.message ?? "Something went wrong" })

        }).finally(() => {
            setLoadingSeed(false)

        });

    }

    const getPriceMint = () => {

        setLoadingPriceApi(true)
        axios.get(`https://api.services.thenemesis.io/v1/landMint/price`, {
            headers: {
                'x_api_key': API_KEY
            }
        }).then((res) => {
            setLoadingPriceApi(false)
            setPriceApi(res.data.price)
        }).catch((err) => {
            console.error({ err })
            setLoadingPriceApi(false)
        })

    }

    const getPrice = async (tokenId?: string) => {
        setLoadingPriceChain(true)
        let p = await price()
        setCost(p?.formattedPriceasNumber ?? 0)
        setPublicIsOpen(p?.publicIsOpen ? true : false)
        setLoadingPriceChain(false)


        if (!!tokenId) {
            setTotalCostCostWei(p?.price)
            setTotal(p?.formattedPriceasNumber ?? 0)
        }
    }

    const getIdTokenToMint = useCallback(
        () => {
            if (tokenSelected) {
                return { token_id_land: tokenSelected }
            } else {
                return { token_id_land: [tokenIdSearched] }
            }
        },
        [tokenSelected, tokenIdSearched]
    )

    const getLandDataBySeed = (_landSeed: number) => {
        let data = [];
        for (let j = 0; j < 5; j++) {
            data[j] = [false, false, false, false, false];
            for (let k = 0; k < data[j].length; k++) {
                data[j][k] = (_landSeed & Math.pow(2, k + j * data[j].length)) != 0;
            }
        }
        data.reverse();
        return data;
    }

    const mint = useCallback(async () => {

        const ids = getIdTokenToMint()

        const token_id_land = ids?.token_id_land
        const tokenIdLand = token_id_land?.map((token) => parseInt(token))
        if (!tokenIdLand?.[0]) return
        if (tokenIdLand?.length > availableLands) {
            setModal({ isOpen: true, type: "error", title: "Error", message: `Ops, you can buy only ${availableLands.toString()} lands this month.` })
            return
        }

        if (totalCostWei && address) {
            let minted = await mintPublic(tokenIdLand, totalCostWei)
            if (minted) {
                // burnTokens(tokenIdLand) 
                incrementBalance(tokenIdLand.length)
            }
        }

    }, [totalCostWei, address, availableLands, getIdTokenToMint])

    useEffect(() => {
        getRefQuery()
        getPriceMint()
    }, [])

    const debouncedToken = useDebounce(tokenIdSearched, 500)

    useEffect(() => {

        if (debouncedToken) {
            getSeed(debouncedToken)
            getPrice(debouncedToken)
        }

    }, [debouncedToken, tokenIdSearched])

    useEffect(() => {
        if (tokenSelected) {
            getSeed(tokenSelected?.[0])
        }
    }, [tokenSelected])


    useEffect(() => {
        if (address && correctChain && contractNFT) {
            getPrice(tokenSelected?.[0])
        }
    }, [address, correctChain, contractNFT, tokenSelected])

    useEffect(() => {
        if (!tokenSelected && !tokenIdSearched && !debouncedToken) {
            setLandSeed(0)
        }
    }, [tokenSelected, tokenIdSearched, debouncedToken])

    const reservationLists = useMemo(() => {
        if (!landSeed) {
            return <tr className="row w-100 mb-2 align-content-center">
                <td className="text-left">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <h4>
                                        No land selected, plese visit
                                        <a className='' href="https://thenemesis.io/">  https://thenemesis.io/</a> to choose your land.
                                        or search token id below.
                                    </h4>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        }

        const landMatrix = getLandDataBySeed(landSeed);


        return (
            <tr className="row w-100 mb-2 align-content-center">
                <td className="text-right">
                    <table id={`seed-${landSeed}`} className="land-plot">
                        <tbody>
                            {landMatrix.map((row, rindex) => {
                                return <tr key={rindex}>{row.map((col, cindex) => {
                                    return <td key={rindex + "_" + cindex} className={col ? "p1-ground" : "p1-filler"}></td>
                                })}</tr>
                            })}
                        </tbody>
                    </table>
                    <div className=''> #{tokenSelected ?? tokenIdSearched} </div>
                </td>
            </tr>
        )

    }, [landSeed, tokenSelected, tokenIdSearched, debouncedToken])


    const showTotal = useMemo(() => {
        return (
            <h2 className='divider'>Total: <span id="amount"></span>{landSeed ? total : 0} NEMS
            </h2>
        )
    }, [total, landSeed, logo])



    const showAccount = useMemo(() => {
        if (address) {
            return ensName ? ensName : displayFormat(address, 8)
        }
    }, [ensName, address])

    return (
        <div className='container-mint'>
            <div className="inner-container mint row flex-wrap">
                <div className="col-xs-12 col-lg-5 p-20">
                    <div className="glass-card wrap-summary">
                        <h4 className='address mb-1'> {`Address: ${showAccount}`}</h4>
                        <h4 className='address '> {`Lands you can still buy this month: ${availableLands}`}</h4>
                        <h1>CHECKOUT</h1>
                        <div className='d-flex align-items-center'>
                            <img className='logo-nems' src={logo} alt="logo-nems" />
                            <h2 className='m-0'>
                                {loadingPriceApi ?
                                    <>
                                        <Loader />
                                    </>
                                    :
                                    <>
                                        {`$ ${priceApi}`}
                                    </>
                                }

                            </h2>
                        </div>
                        <div className='d-flex align-items-center mt-1'>
                            <h2 className='m-0 d-flex align-items-center'>Price per Land:
                                {loadingPriceApi ?
                                    <>
                                        <Loader />
                                    </>
                                    :
                                    <>
                                        {`${cost} NEMS`}
                                    </>
                                }
                            </h2>
                        </div>

                        {showTotal}

                        <label className="checkbox-wrapper-40">
                            <input onChange={(e) => setTermsChecked(e.target.checked)} type="checkbox" id="terms" /> I read and accept The Nemesis <a href="https://thenemesis.io/terms" target="_blank">Terms & Conditions</a>
                        </label>

                        <button
                            disabled={!termsChecked || limitReached || !totalCostWei || !!ownerAddress}
                            onClick={() => mint()} id="mint-now"
                            className={clsx('button w-100 mt-2 cursor-pointer',
                                { "cursor-inherit": !termsChecked || limitReached || !totalCostWei },
                                { "disabled": !tokenIdSearched })
                            }>
                            MINT NOW</button>
                        {limitReached && <h4 className='address text-danger m-0 mt-1 text-center pt-1'>Maximum monthly expence reached</h4>}
                        <button
                            onClick={() => setModalWalletIsOpen(true)}
                            className='button w-100 mt-1 cursor-pointer'>LOGOUT</button>
                    </div>
                </div>

                <div className="col-xs-12 col-lg-7 p-20">
                    <div className="glass-card" id="wrap-list">
                        <h1>Selected land</h1>

                        {loadingSeed && <Loader />}

                        <form>
                            <table id="reservation-lists w-100 debug">
                                <tbody className=''>
                                    {reservationLists}
                                </tbody>
                            </table>
                        </form>

                        {!!ownerAddress && tokenIdSearched &&
                            <h4 className='text-danger'>Land alredy minted</h4>
                        }

                        {!tokenSelected && <div className='d-flex'>
                            <NumericFormat
                                placeholder='Token id'
                                className='input-token-id'
                                value={tokenIdSearched}
                                type="text"
                                decimalScale={0}
                                allowLeadingZeros={false}
                                isAllowed={(values) => {
                                    const { floatValue } = values;
                                    return (floatValue ?? 0) < MAX_SUPPLY;
                                }}
                                onValueChange={values => {

                                    if (!values.floatValue || values.floatValue === undefined) {
                                        setTokenIdSearched("")
                                        setTotal(0)
                                        setLandSeed(0)
                                        setTotalCostCostWei(BigInt("0n"))
                                        return
                                    }

                                    if (values?.floatValue && values?.floatValue <= MAX_SUPPLY) {
                                        setTokenIdSearched(values.formattedValue);
                                    }

                                    if (values?.floatValue && values?.floatValue > MAX_SUPPLY) {
                                        setTokenIdSearched(MAX_SUPPLY.toString());
                                    }


                                }}
                            />
                        </div>}




                    </div>

                </div>
            </div>
        </div >
    )
}