import React, { ReactNode, useMemo } from 'react'
import Loader from '../Loader/Loader'

interface iGlassCard {
    address?: string
    isLoading?: boolean
    title?: string
    text?: string
    children?: ReactNode
}

const CardGlass = ({ address, isLoading, title, text, children }: iGlassCard) => {


    return (
        <div className='container-coming-soon '>
            <div className="inner-container row ">
                <div className="col-xs-12 col-sm-10 col-md-8 col-lg-6 h-100 align-items-center d-flex">
                    <div className="glass-card box w-100 font-size">
                    <div>
                    <h4 className='address'> {address}</h4>
                        </div>
                        <div className='position-relative'>
                            <h1>{title}</h1>
                        </div>
                        <div>
                            {isLoading && <Loader />}
                            <p>{text}</p>
                        </div>
                            {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardGlass