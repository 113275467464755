import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useCallback, useEffect, useState } from 'react';
import { BigNumberish, TransactionReceipt, ethers, FixedNumber } from 'ethers';

import { ABI_NFT, ABI_USDT, ABI_USDC, ABI_TOKEN, CHAIN_ID, CONTRACT_ADDRESS_NFT, CONTRACT_ADDRESS_USDT, CONTRACT_ADDRESS_NEMS, CONTRACT_ADDRESS_USDC, connectors } from '../const/const';
import { Modal } from '../../types/Context';
import { useAppContext } from '../context/AppContext';


export const useChain = () => {

    const { activate, deactivate, active, account: address, library, chainId } = useWeb3React();
    const { setModal, setModalWalletIsOpen, setLandMinted } = useAppContext()
    const [correctChain, setCorrectChain] = useState(false)
    const [ensName, setEnsName] = useState("")

    const w = window as any
    const ethereum = w.ethereum

    let signer = library?.getSigner();

    const contractNFT = new ethers.Contract(CONTRACT_ADDRESS_NFT, ABI_NFT, signer);
    const contractUSDT = new ethers.Contract(CONTRACT_ADDRESS_USDT, ABI_USDT, signer);
    const contractUSDC = new ethers.Contract(CONTRACT_ADDRESS_USDC, ABI_USDC, signer);
    const contractNEMS = new ethers.Contract(CONTRACT_ADDRESS_NEMS, ABI_TOKEN, signer);

    useEffect(() => {
        if (chainId === CHAIN_ID) {
            setCorrectChain(true)
        }
    }, [chainId])

    const toHex = (num: number) => {
        const val = Number(num);
        return "0x" + val.toString(16);
    };

    const displayFormat = (address: string, qtyLetters: number) => {
        return address?.slice(0, qtyLetters) + "..." + address?.slice(address.length - qtyLetters, address.length)
    }

    const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

    const logout = () => {
        deactivate()
        localStorage.clear()
    }

    useEffect(() => {
        if (address) {
            addrToEns(address)
        }
    }, [address])

    const addrToEns = async (address: string) => {
        try {
            const name = await library.lookupAddress(address);
            setEnsName(name);
        } catch (error) {
            setEnsName("");
        }
    }

    const switchNetwork = async () => {
        try {
            await ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: toHex(CHAIN_ID) }]
            })
        } catch (switchError: any) {

            if (switchError.code === 4902) {
                try {
                    await ethereum.request({
                        method: "wallet_addEthereumChain",
                        params: [{ chainId: toHex(CHAIN_ID) }]
                    });
                } catch (error) {
                    setModal?.({ isOpen: true, type: "error", title: "ERROR", message: "Please add corerct chain to your wallet" })
                }
            }
        }
    };

    const getActualChainId = () => {
        if (ethereum && ethereum.isMetaMask && ethereum?.networkVersion) {
            return ethereum?.networkVersion
        }
    }

    const connectWallet = async (connector: AbstractConnector) => {
        if (connector) {
            try {

                if (connector instanceof InjectedConnector) {
                    if (!ethereum) {
                        setModal?.({ isOpen: true, type: "error", title: "ERROR", message: "Please install Metamask extension" })
                        setModalWalletIsOpen?.(false)
                        return
                    }

                    if (ethereum && ethereum.isMetaMask) {
                        await switchNetwork()

                        if (ethereum.setSelectedProvider) {
                            let provIsMetaMask = ethereum.providers?.find((p: any) => p.isMetaMask)
                            ethereum.setSelectedProvider(provIsMetaMask)
                            await switchNetwork()
                        }

                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                            await switchNetwork()
                        }

                    } else {
                        setModal?.({ isOpen: true, type: "error", title: "ERROR", message: "Please install Metamask extension" })
                        setModalWalletIsOpen?.(false)
                        return
                    }
                }

                activate(connector,
                    (error: Error) => {
                        console.error({ interno: error });

                        if (error.name === "UnsupportedChainIdError") {
                            setModal?.({ isOpen: true, type: "error", title: "ERROR", message: "Please change network to Ethereum mainnet" })
                            setModalWalletIsOpen?.(false)
                            return
                        }
                        setModal?.({ isOpen: true, type: "error", title: "ERROR", message: error.message ?? "Something went wrong" })
                        setModalWalletIsOpen?.(false)
                        return

                    }, true)
                    .then(() => {
                        setProviderLS("InjectedConnector")
                        setModalWalletIsOpen?.(false)
                        return
                    })
                    .catch(error => {
                        if (error.name === "UnsupportedChainIdError") {
                            setModal?.({ isOpen: true, type: "error", title: "ERROR", message: "Please change network to Ethereum mainnet" })
                            setModalWalletIsOpen?.(false)
                            return
                        }
                        setModal?.({ isOpen: true, type: "error", title: "ERROR", message: error.message ?? "Something went wrong" })
                        setModalWalletIsOpen?.(false)
                        return
                    })

            } catch (error: any) {
                setModal?.({ isOpen: true, type: "error", title: "ERROR", message: error.message ?? "Something went wrong" })
                setModalWalletIsOpen?.(false)
                return
            }
        }
    }

    const setProviderLS = (type: any) => {
        window.localStorage.setItem("provider", type);
    };

    const reconnect = useCallback(
        () => {
            if (!active && !address) {
                let provider = window.localStorage.getItem("provider");
                { provider === "InjectedConnector" && connectWallet(connectors.injected) }
                { provider === "WalletConnectConnector" && connectWallet(connectors.walletConnect) }
                { provider === "WalletLinkConnector" && connectWallet(connectors.coinbaseWallet) }
            }
        }, []
    )

    const handleTnx = async (txn: any, isApproval?: boolean, qtyLands?: number) => {
        txn.wait();
        setModal({ isOpen: true, type: "info", title: "Waiting blockchain", message: "Wait the blockchain" })

        await library.waitForTransaction(txn.hash);
        const receipt: TransactionReceipt = await library.getTransactionReceipt(txn.hash);

        if (receipt.status === 1) {
            if (isApproval) {
                setModal({ isOpen: true, type: "info", title: "Success", message: "Your token has been approved, to mint please approve next transaction" })
            } else {
                setModal({ isOpen: true, type: "success", title: "Thank you!", message: `Thank you for buying ${qtyLands} ${qtyLands === 1 ? "Land" : "Lands"}! \n Tweet about your purchase now to win 1O$ in The Nemesis COINS! \nTo redeem, simply send proof of your tweet link in our Discord (#coins claim channel). \nYou'll receive the reward in the next 72 hours.` })
            }
            return true

        } else {
            setModal({ isOpen: true, type: "error", title: "ERROR", message: "Something went wrong" })
            setLandMinted(0)
            return false
        }
    }

    const price = useCallback(
        async () => {
            if (address && correctChain && contractNFT) {
                try {

                    let price = await contractNFT.getLandPrice()
                    let publicIsOpen: boolean = await contractNFT.isPublicOpen()

                    let formattedPrice = ""

                    if (publicIsOpen) { //NEMS
                        formattedPrice = parseFloat(ethers.formatEther(price)).toFixed(2)
                    } else { //USDC
                        formattedPrice = ethers.formatUnits(price, 6)
                    }

                    let formattedPriceasNumber = parseFloat(formattedPrice)
                    return { price, formattedPrice, formattedPriceasNumber, publicIsOpen }

                } catch (error: any) {
                    setModal({ isOpen: true, type: "error", title: "ERROR", message: error.message ?? "Something went wrong" })
                    return
                }
            }
        }, [address, correctChain, contractNFT],
    )

    const calculateGasMargin = (value: bigint): BigInt => {
        return BigInt(BigInt(value) * BigInt(120) / BigInt(100))
    }

    const setApproval = useCallback(
        async (price: string, contract: ethers.Contract) => {

            try {

                const allowance = await contract.allowance(address, CONTRACT_ADDRESS_NFT)

                if (allowance >= price) {
                    return true
                }

                const estimatedGas = await contract.approve.estimateGas(address, price)
                const marg = calculateGasMargin(estimatedGas.valueOf());

                setModal({ isOpen: true, type: "info", title: "Token approve", message: "Please wait notification and complete the operation to continue" })
                const res: boolean = await contract.approve(CONTRACT_ADDRESS_NFT, price, { gasLimit: marg })
                    .then(async (nftTxn: any) => {
                        return await handleTnx(nftTxn, true)

                    }).catch((error: any) => {

                        setModal?.({ isOpen: true, type: "error", title: "ERROR", message: error?.reason ?? error.message ?? "Something went wrong" })
                        return false

                    })
                return res

            } catch (error: any) {

                setModal({ isOpen: true, type: "error", title: "ERROR", message: error?.reason ?? error.message ?? "Something went wrong" })
                return false
            }

        },
        [address],
    )


    const mintReserved = async (tokenIds: number[], isUsdtPayment: boolean, amount: bigint) => {
        try {
            setModal({ isOpen: true, type: "info", title: "Loading wallet permission", message: "Please wait notification and complete the operation to continue" })

            let contract = contractUSDC

            if (isUsdtPayment) {
                contract = contractUSDT
            }

            const approveTokens = await setApproval(amount.toString(), contract)

            if (!approveTokens) {
                return false
            }
            const estimatedGas = await contractNFT.mintReservedLands.estimateGas(tokenIds, isUsdtPayment)
            const marg = calculateGasMargin(estimatedGas.valueOf());

            const minted: boolean = await contractNFT.mintReservedLands(tokenIds, isUsdtPayment, { gasLimit: marg })
                .then(async (nftTxn: any) => {
                    setLandMinted(tokenIds.length)
                    return await handleTnx(nftTxn, false, tokenIds.length)

                }).catch((error: any) => {

                    const errorMessage = error?.error?.data?.message ?? error?.reason ?? error?.message ?? "Something went wrong";
                    const isTooLowGas = error?.message?.toLowerCase().includes('transaction underpriced');
                    const description = isTooLowGas ? 'Your gas fee are too low, set to high to ensure the transaction confirmation' : errorMessage

                    setModal({ isOpen: true, type: "error", title: "ERROR", message: description })
                    setLandMinted(0)

                    return false
                })

            return minted

        } catch (error: any) {
            setLandMinted(0)
            setModal({ isOpen: true, type: "error", title: "ERROR", message: error?.reason ?? error?.message ?? "Something went wrong" })
            return false
        }
    }

    const mintPublic = async (tokenIds: number[], amount: bigint) => {

        try {
            setModal({ isOpen: true, type: "info", title: "Loading wallet permission", message: "Please wait notification and complete the operation to continue" })

            const approveTokens = await setApproval(amount.toString(), contractNEMS)

            if (!approveTokens) {
                return false
            }


            const minted: boolean = await contractNFT.mintPublicLands(tokenIds)
                // const minted: boolean = await contractNFT.mintPublicLands(tokenIds)
                .then(async (nftTxn: any) => {

                    return await handleTnx(nftTxn)

                }).catch((error: any) => {

                    const errorMessage = error?.error?.data?.message ?? error?.reason ?? error?.message ?? "Something went wrong";
                    const isTooLowGas = error?.message?.toLowerCase().includes('transaction underpriced');
                    const description = isTooLowGas ? 'Your gas fee are too low, set to high to ensure the transaction confirmation' : errorMessage

                    setModal({ isOpen: true, type: "error", title: "ERROR", message: description })
                    return false
                })

            return minted

        } catch (error: any) {

            setModal({ isOpen: true, type: "error", title: "ERROR", message: error?.message ?? "Something went wrong" })
            return false
        }
    }

    const air = async () => {
        // try {
        //     setModal({ isOpen: true, type: "info", title: "Loading wallet permission", message: "Please wait notification and complete the operation to continue" })

        //     const airdropped: boolean = await contractNFT.airdrop([399,400], ["0xdA200867a023EEdB08aAB17f0417AF950ac719E5","0xdA200867a023EEdB08aAB17f0417AF950ac719E5"])
        //         .then(async (nftTxn: any) => {
        //             return await handleTnx(nftTxn, false)

        //         }).catch((error: any) => {

        //             const errorMessage = error?.error?.data?.message ?? error?.reason ?? error?.message ?? "Something went wrong";
        //             const isTooLowGas = error?.message?.toLowerCase().includes('transaction underpriced');
        //             const description = isTooLowGas ? 'Your gas fee are too low, set to high to ensure the transaction confirmation' : errorMessage

        //             setModal({ isOpen: true, type: "error", title: "ERROR", message: description })

        //             return false
        //         })

        //     return airdropped

        // } catch (error: any) {
        //     setModal({ isOpen: true, type: "error", title: "ERROR", message: error?.reason ?? error?.message ?? "Something went wrong" })
        //     return false
        // }
    }


    return {
        connectWallet,
        reconnect,
        displayFormat,
        price,
        switchNetwork,
        getActualChainId,
        correctChain,
        mintReserved,
        mintPublic,
        logout,
        address,
        ensName,
        contractNFT,
        air
    }
}
