import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Mint } from './components/Mint/Mint';
import { useAppContext } from './shared/context/AppContext';
import { useWeb3React } from '@web3-react/core';
import { useChain } from './shared/hooks/useConnectChain';
import { API_KEY, coinBaseAppLink, connectors, endpoint, metamaskAppLink } from './shared/const/const';
import { initialStateModal } from './shared/context/initialState';
import { Modal } from './components/molecules/Modal';
import Loader from './components/molecules/Loader/Loader';
import axios from 'axios';
import { Data, MarketsResponse } from './types/types';
import CardGlass from './components/molecules/CardGlass/CardGlass';
import { CountDown } from './components/molecules/CountDown';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { YoutubeEmbed } from './components/molecules/Youtube/YoutubeEmbed';
import { useOutsideClick } from './shared/hooks/useOutsideClick';

function App() {

  const { modalWalletIsOpen, setModalWalletIsOpen, setModal, modal, landMinted } = useAppContext()
  const { connectWallet, reconnect, logout } = useChain();
  const { address, ensName, displayFormat } = useChain();
  const { correctChain } = useChain();
  const [data, setData] = useState<Data[]>()
  const [isLoadingCompanionHolded, setIsLoadingCompanionHolded] = useState(false)
  const [isLoadingLands, setIsLoadingLands] = useState(false)
  const [companionHolded, setCompanionHolded] = useState<number>(0)
  const [countdownCompleted, setCountdownCompleted] = useState<boolean>(false)
  const [openVideo, setOpenVideo] = useState<boolean>(false)
  const [limitReached, setLimitReached] = useState<boolean>(true)
  const [amount, setAmount] = useState<number>(0)
  const [availableLands, setAvailableLands] = useState<number>(0)
  const [loadingInfo, setLoadingInfo] = useState<boolean>(false)


  const [isMobile, setMobile] = useState<boolean>()
  const w = window as any;

  const showAccount = useMemo(() => {
    if (address) {
      return ensName ? ensName : displayFormat(address, 8)
    }
  }, [ensName, address])

  useEffect(() => {
    var mobile = /Android|webOS|iPhone|iPod|Blackberry/.test(navigator.userAgent);
    setMobile(mobile)
  }, [])

  useEffect(() => {
    reconnect();
  }, []);

  useEffect(() => {
    if (address && correctChain) {
      // getLands(address)
      getBalance(address)
    }
  }, [address, correctChain])

  useEffect(() => {
    if (address && correctChain && data && data?.length === 0) {
      // getBalanceOfCompanion(address)
    }
  }, [address, correctChain, data])

  const handleClickOutside = () => {
    setOpenVideo(false);
  };

  const handleOutsideClick = (event: Event) => {
    setOpenVideo(false);
    event.stopPropagation();
  };

  const ref = useOutsideClick(handleClickOutside);

  const getLands = async (walletAddress: string, notloading?: boolean) => {
    { !notloading && setIsLoadingLands(true) }

    try {
      const res = await axios.get(`${endpoint}/lands/view_res_s1_comp_land/${walletAddress.toLowerCase()}`);
      setData(res.data)
    } catch (err: any) {
      setModal({ isOpen: true, type: "error", title: "Error loading lands", message: err?.message ?? "Something went wrong" })
    } finally {
      { !notloading && setIsLoadingLands(false) }
    }
  }

  const getBalance = async (walletAddress: string) => {
    setLoadingInfo(true)
    try {
      const res = await axios.get<MarketsResponse>(`${endpoint}/markets/${walletAddress}`);

      let limitReached: boolean = false
      let amount: number = 0
      let availableLands: number = 0

      limitReached = res.data?.canBuy ? false : true
      amount = res.data?.totalAmount ?? 0
      availableLands = res.data?.numLands ?? 0

      if (res) {
        setLimitReached(limitReached)
        setAmount(amount)
        setAvailableLands(availableLands)
      } else {
        setLimitReached(false)
        setAmount(0)
        setAvailableLands(0)
      }
      setLoadingInfo(false)

    } catch (err: any) {

      setLoadingInfo(false)
      setModal({ isOpen: true, type: "error", title: "Error loading remaining balance", message: err?.message ?? "Something went wrong" })
    }
  }

  const getBalanceOfCompanion = async (walletAddress: string) => {
    setIsLoadingCompanionHolded(true)
    try {
      const res = await axios.get(`https://api.thenemesis.io/v5/blockchain/balanceof/0x3bbfea5632490f4d09b9a167633929bd17356e18/${walletAddress.toLowerCase()}/0/ERC721`, {
        headers: {
          'x_api_key': API_KEY
        }
      });
      setCompanionHolded(parseInt(res.data.numItems))
    } catch (err: any) {
      setModal({ isOpen: true, type: "error", title: "Error loading companion", message: err?.message ?? "Something went wrong" })
    } finally {
      setIsLoadingCompanionHolded(false)
    }
  }

  const burnTokens = useCallback(
    async (token_id: number[]) => {
      try {
        const res = await axios.post(`https://api.thenemesis.io/v5/lands/burnCompanionLandBenefit`, { token_id }, {
          headers: {
            'x_api_key': API_KEY
          }
        });

        if (res.status === 200 && address) {
          getLands(address, true)
        }
      } catch (err: any) {
        setModal({ isOpen: true, type: "error", title: "Error burn token", message: err?.message ?? "Something went wrong" })
      }
    },
    [address],
  )

  const incrementBalance = useCallback(
    async (qty: number) => {
      try {
        const total = 280 * qty

        const res = await axios.post(`${endpoint}/markets`, {
          "address": address,
          "amount": total
        }, {
          headers: {
            'x_api_key': API_KEY
          }
        });

        if (res && address) {
          getBalance(address)
        }

      } catch (err: any) {
        setModal({ isOpen: true, type: "error", title: "Error", message: err?.message ?? "Something went wrong" })
      }
    },
    [address],
  )

  const doLogout = () => {
    logout();
    setModalWalletIsOpen(false);
    setData([])
    setCompanionHolded(0)
  }

  const buttonTwitter = useMemo(() => {
    const previewUrl = 'https://thenemesis.io/lands/'
    const tweetText = `I just bought a land in @TheNemesis_io metaverse! 🚀 Join to build your own world 👉🏼 ${previewUrl}`;
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;
    return (

      <div className='d-flex justify-content-around flex-wrap mt-1'>
        <a className='' href={twitterShareUrl} target='_blank'>
          <button className="button cursor-pointer" onClick={() => { }}>SHARE NOW</button>
        </a>
      </div>
    )
  }, [landMinted])

  const mint = useMemo(() => {

    if (!countdownCompleted) {
      return (
        < CardGlass
          title='Coming Soon'
          text="We are working to come back soon!" >
          <CountDown date={'2023-01-22T15:00:00Z'} setCountdownCompleted={setCountdownCompleted} />
          <div className='d-flex justify-content-around flex-wrap'>
            <a className="mt-1 button " target='_blank' href="https://thenemesis.io/lands/">MORE INFO</a>
          </div>
        </CardGlass >)
    }

    if (isLoadingLands || loadingInfo) {
      return <CardGlass isLoading title='LOADING' />
    }

    if (!address) {
      return <CardGlass
        title='Sync wallet'
        text="Sync your wallet owning your Companions to buy your Lands in Public sale">
        <div className='d-flex justify-content-around flex-wrap'>
          <button className="mt-1 button cursor-pointer" onClick={() => setModalWalletIsOpen(true)}>CONNECT WALLET</button>
          <button className=" mt-1 button cursor-pointer" onClick={() => setOpenVideo(true)}>TUTORIAL</button>
        </div>
      </CardGlass>
    }

    return <Mint
      getLands={getLands}
      burnTokens={burnTokens}
      incrementBalance={incrementBalance}
      amount={amount}
      limitReached={limitReached}
      availableLands={availableLands}
    />


  }, [address, , isLoadingLands, countdownCompleted, limitReached, amount, loadingInfo])

  const handleCloseModal =
    () => {
      setModal({
        isOpen: false,
        type: "",
        title: "",
        message: ""
      })

      if (modal.type === "success") {
        window.location.href = "/"
      }
    }


  return (
    <>

      {mint}

      {/* modal connect wallet / logout */}
      <Modal
        isOpen={modalWalletIsOpen}
        onClose={() => { setModalWalletIsOpen(false) }}
        childComponent={
          <>
            {isMobile && !w.ethereum ?
              <>
                <a href={isMobile ? metamaskAppLink : ''} className="">
                  <button className="button cursor-pointer">METAMASK</button>
                </a>

                {/* <a href={isMobile ? coinBaseAppLink : ''} className="">
                  <button className="button cursor-pointer mt-1" onClick={() => connectWallet(connectors.coinbaseWallet)}>COINBASE</button>
                </a>

                <button className="button cursor-pointer mt-1" onClick={() => connectWallet(connectors.walletConnect)}>WALLET CONNECT</button> */}
              </> :
              (address ?
                <>
                  <h1 className='w-100 text-center text-white'>You are going to logout</h1>
                  <div className="d-flex justify-content-around mt-1">
                    <button className="button cursor-pointer" onClick={() => setModalWalletIsOpen(false)}>CANCEL</button>
                    <button className="button cursor-pointer" onClick={() => { doLogout(); setModalWalletIsOpen(false) }}>LOGOUT</button>
                  </div>
                </>
                :
                <div className="d-flex flex-column h-100 justify-content-around">
                  <button className="button cursor-pointer" onClick={() => connectWallet(connectors.injected)}>METAMASK</button>
                  {/* <button className="button cursor-pointer mt-1" onClick={() => connectWallet(connectors.walletConnect)}>WALLET CONNECT</button>
                  <button className="button cursor-pointer mt-1" onClick={() => connectWallet(connectors.coinbaseWallet)}>COINBASE</button> */}
                </div>
              )
            }

          </>
        }
      />

      {/* modal messages */}
      <Modal
        message
        isOpen={modal.isOpen}
        onClose={() => {
          handleCloseModal()
        }}
        childComponent={
          <div className='w-100'>
            <h1 className='w-100 text-center text-white'>{modal.title}</h1>
            <div className="w-100 text-center mt-1 text-white">
              {modal.message.split('\n').map((x, i) => <div key={i}>{x}</div>)}
            </div>
            <div className="w-100 text-center"> {modal.type === "info" && <Loader />}</div>
            {modal.type === "success" && buttonTwitter}
          </div>
        }
      />

      <YoutubeEmbed ref={ref} handleOutsideClick={handleOutsideClick} openVideo={openVideo} />

    </>
  );
}
export default App;