import './CountDown.css';
import Countdown, { CountdownRenderProps } from 'react-countdown';

export interface CountDown {
    date: string;
    setCountdownCompleted: (completed :boolean)=> void
}

export const CountDown = ({date, setCountdownCompleted}: CountDown) => {
    const renderer = ({ formatted, completed }: CountdownRenderProps) => {
        setCountdownCompleted(completed)
        return (
            <>
                <div className="">
                    {!completed && (
                        <div className="">
                            <h3 className='title-countdown'>{formatted.hours}h:{formatted.minutes}m:{formatted.seconds}s</h3>
                        </div>
                    )}
                </div>
            </>
        );
    }

    return (
        <Countdown
            autoStart
            date={date}
            renderer={renderer} />
    )
}